import {
    module
  } from 'modujs';
  import {
    html
  } from '../utils/classes';
  import imagesLoaded from 'imagesLoaded';

  export default class extends module {
    constructor(m) {
      super(m);
      
    }
  
    init() {
      imagesLoaded( this.el, function() {
        html.classList.add('has-images-loaded');
      });
    }

    destroy () {
      super.destroy();
    }

  }