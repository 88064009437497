import { module } from 'modujs';
import {
  html
} from '../utils/classes';
import skope from 'skope';
import Swiper, {Scrollbar,FreeMode} from 'swiper';

export default class extends module {
    constructor(m) {
        super(m)
    }

    init() {

      this.initSlider();
        
    }

    initSlider() {

      if (this.el.classList.contains('w-dyn-list')) {
        var el = document.createElement('div');
        el.className = 'swiper-scrollbar-wrapper';
        el.setAttribute('data-slider', 'scrollbar')
        this.el.appendChild(el);
      }

      if (skope.swiper.mobile == false && html.classList.contains('is-mobile'))
      
      return;

        this.swiper = new Swiper(this.el, {
          modules: [Scrollbar, FreeMode],
          // modules: [FreeMode],
          preloadImages: false,
          grabCursor: true, 
          scrollbar: {
            el: '.swiper-scrollbar-wrapper', 
            draggable: true
          },
          freeMode: {
            enabled: skope.swiper.freemode || true,
            sticky: skope.swiper.sticky || true
          },
          lazy: {
            checkInView: true,
            loadPrevNext: true
          },
          slidesPerView: 'auto',
          touchStartPreventDefault: 'false',
          watchSlidesProgress: true,
          on: {
            init: function() {
            },
            lock: function() {
              this.el.classList.add("swiper-lock");
            },
            touchStart: function() {
            },
  
            touchEnd: function() {
            }
          }  
        });

        this.call('updateScroll', null, 'Scroll');

    }

    inview(e) {
      if(e.target == this.el) {
          this.swiper.lazy.load()
      }
  }

    destroy() {
      super.destroy()
      if (!this.swiper == null)
      this.swiper.destroy();
    }
    
}