import { module } from 'modujs';
import { html } from '../utils/classes';
import { getStyleProperty } from '../utils/classes.js';
import skope from 'skope';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: skope.scroll.smooth || false,
            getDirection: true
        });

        this.scroll.on('scroll', (instance) => {
          document.documentElement.setAttribute('data-direction', instance.direction);
        });
        this.scroll.on('scroll', (scroll) => {
          if(scroll < getStyleProperty('--nav-height')) {
            html.classList.add('is-top')
            } else {
              html.classList.remove('is-top')
          }
        });

        this.scroll.on("call", (value, way) => {
      
      
           // Add or remove ui classes
           // Used to control different color ui themes i.e light, dark 
          if (value.includes('ui-') || value.includes('has-')) {
            if (way === 'enter') {
              html.classList.add(value)
            } else if (way === 'exit') {
              html.classList.remove(value)
            }
          }
          if (value.includes('rev-ui-') || value.includes('rev-has-')) {
            var newValue = value.replace('rev-','');
            if (way === 'exit') {  
              html.classList.add(newValue)
            } else if (way === 'enter') {
              html.classList.remove(newValue)
            }
          }
      
        });

        this.updateScroll();
        window.addEventListener('resize', this.onResize = () => this.updateScroll())
    }
    updateScroll() {
      this.scroll.update();
    }
    update() {
      this.scroll.init();
    }
    destroy() {
        this.scroll.destroy();
        super.destroy();
        window.removeEventListener('resize', this.onResize);
    }
}

