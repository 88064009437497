import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/classes';
import Webflow from 'webflow';
import { webflowEditor } from './modules/WebflowEditor.js';

const app = new modular({
  modules: modules
});

window.onload = () => {
    html.classList.add('has-no-js');
    Webflow.env('editor') != undefined ? webflowEditor() : init();
}; 

function init() {
  globals();
  app.init(app);
  html.classList.remove('has-no-js');
  html.classList.remove('is-loading');
  html.classList.add('is-loaded');
  html.classList.add('is-ready');
}