import { module} from 'modujs';
import { getHeight, heightRound } from '../utils/height.js';
import { getStyleProperty } from '../utils/classes.js';

export default class extends module {
  constructor(m) {
      super(m);
  }

  init() {
    this.update();
  }

  baselineSet(e) {
    e.style.height = 'auto';
    e.style.paddingTop = null;
    var height = getHeight(e, 'full');
    var baseline = getStyleProperty('--baseline');
    var newHeight = heightRound(height, baseline);
    e.style.height = newHeight + 'px';
    e.style.paddingTop = '0';
 }

 update() {
  this.baselineSet(this.el);
  window.addEventListener('resize', this.onResize = () => this.baselineSet(this.el))
 }
  
  destroy() {
    super.destroy()
    window.removeEventListener('resize', this.onResize);
  }
}