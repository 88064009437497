import {
    module
  } from 'modujs';
  import {
    html
  } from '../utils/classes';
  import skope from 'skope';
  import Webflow from 'webflow';
  import Swup from 'swup';
  import SwupPreloadPlugin from '@swup/preload-plugin';
  import SwupGtmPlugin from '@swup/gtm-plugin';
  import SwupProgressPlugin from '@swup/progress-plugin';
  import SwupBodyClassPlugin from '@swup/body-class-plugin';
  import SwupA11yPlugin from '@swup/a11y-plugin';
  import SwupRouteNamePlugin from '@swup/route-name-plugin';

  export default class extends module {
    constructor(m) {
      super(m);
      
    }
  
    init() {
      this.swup = new Swup({
        plugins: [
          new SwupA11yPlugin(),
          new SwupRouteNamePlugin(),
          new SwupPreloadPlugin(), 
          new SwupGtmPlugin(),
          new SwupProgressPlugin({
            className: 'swup-progress-bar',
            transition: 300,
            delay: 0,
            initialValue: 0.20,
            hideImmediately: true
          }),
          new SwupBodyClassPlugin()
        ]

      });
      
      this.swup.on('contentReplaced', (containers) => {
        skope.webflow == true ? Webflow.ready() : null ;
        this.call('update', containers, 'app');
      });

      this.swup.on('willReplaceContent', (containers) => {
        skope.webflow == true ? Webflow.destroy() : null ;
        html.classList.remove('has-images-loaded', 'has-nav-open', 'ui-light', 'ui-transparent', 'has-nav-bg');
        this.call('destroy', containers, 'app');      
      });

      this.swup.on('animationOutStart', () => {

      });

      skope.swup.progressbar == false ? html.classList.add('has-no-progress-bar') : null ;

    }

  }