import { module } from 'modujs';
import { html } from '../utils/classes'


export default class extends module {
  constructor(m) {
      super(m);

      this.$header = this.$('header')[0];
      this.$menuButton = this.$('toggle')[0];
      this.$menu = this.$('menu')[0];

      this.events = {
          click: {
              'toggle': 'toggleMenu'
          }
      }
  }

  init() {
          if(this.hasMenuOpened()) {
              this.hideMenu()
          }
  }

    hasMenuOpened() {
      return html.classList.contains('has-nav-open')
  }

  showMenu() {
      html.classList.add('has-nav-open')
      this.$menuButton.setAttribute('aria-expanded', true)
  }

  hideMenu() {
      html.classList.remove('has-nav-open')
      this.$menuButton.setAttribute('aria-expanded', false);
  }

  toggleMenu() {
      if(!this.hasMenuOpened()) {
          this.showMenu()
      } else {
          this.hideMenu();
      }
  }

  destroy() {
    super.destroy()
  }
}