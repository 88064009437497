import skope from "skope";
import svg4everybody from "svg4everybody";
import { html } from "./utils/classes";
import { getHeight } from "./utils/height.js";

export default function () {
	svg4everybody();

	// ==========================================================================
	// isMobile
	// ==========================================================================
	const isMobile =
		/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		) ||
		(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
	window.isMobile = isMobile;

	if (window.isMobile) {
		html.classList.add("is-mobile");
	}

	// vh
	const setVh = function () {
		html.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
	};

	// vw
	const setVw = function () {
		html.style.setProperty("--vw", `${window.innerWidth * 0.01}px`);
	};

	// App height
	const setAppHeight = function () {
		html.style.setProperty("--app-height", `${window.innerHeight}px`);
	};

	// App width
	const setAppWidth = function () {
		html.style.setProperty("--app-width", `${window.innerWidth}px`);
	};

	// Font size
	let rem;
	const setFontSize = function () {
		rem = 1 * parseFloat(getComputedStyle(html).fontSize);
		html.style.setProperty("--font-size", rem);
	};

	// Baseline
	let baselineSetting = skope.baseline ? skope.baseline : 0.5;
	let baselineValue;
	const setBaseline = function () {
		baselineValue =
			baselineSetting * parseFloat(getComputedStyle(html).fontSize);
		html.style.setProperty("--baseline", baselineValue);
	};

	// Nav height
	let nav = document.querySelector(".nav");
	const setNavHeight = function () {
		html.style.setProperty("--nav-height", getHeight(nav, "full"));
	};

	setVw();
	setVh();
	setAppHeight();
	setAppWidth();
	setFontSize();
	setBaseline();
	setNavHeight();

	window.addEventListener("resize", () => {
		setVw();
		setVh();
		setAppHeight();
		setAppWidth();
		setFontSize();
		setBaseline();
		setNavHeight();
	});
}
