import { module } from 'modujs';
import { getHeight } from '../utils/height.js';

export default class extends module {
  constructor(m) {
      super(m);

      this.$items = this.$('item');
      this.$reveals = this.$('reveal');

      this.events = {
          click: {
            'toggle': 'toggleAccordion'
          }
      }
  }

  init() {
    this.update();
  }

  update() {
    if (this.getData('open')) {
      this.$('item')[0].classList.add('is-open');
    }
    this.setAccordionHeight(this.$reveals);

    window.addEventListener('resize', this.onResize = () => this.setAccordionHeight(this.$reveals))
  }

  setAccordionHeight(e) {
    for(let reveal of e) {  
    const content = reveal.querySelector('[data-accordion="content"]');
    var height = getHeight(content, 'full');
    reveal.style.maxHeight = height + 'px';
    }
  }
  closeAccordions() {
    for(let item of this.$items) {
      item.classList.remove('is-open')
    }
  }

  toggleAccordion(e) {
    const target = e.currentTarget;
    const item = this.parent('item', target);
    if (item.classList.contains('is-open')) {
      item.classList.remove('is-open');
    } else {
      if (this.$('item.is-open').length > 0) {
      this.$('item.is-open')[0].classList.remove('is-open');
      }
      item.classList.add('is-open');
      this.call('updateScroll', null, 'Scroll');
  }
  }

  destroy() {
    super.destroy()
      window.removeEventListener('resize', this.onResize);
  }
}