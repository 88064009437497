/**
 * Rounds a number to the nearest increment
 *
 * @param {string} value - The number
 * @param {string} interval - The increment i.e 6, 8, 10,
 * @return {string} The number rounded to the nearest interval value
 */

export function heightRound(value, interval) {
	return Math.round(value / interval) * interval;
}

/**
 * Sets the height of an element rounded to the nearest baseline grid increment i.e 6px, 8px, 10px, 12px
 * Use on elements that have a fixed aspect ratio such as images
 * Can be used on text that doesn't have a line-height divisible by the baseline to resset the text block to the grid
 * Recommneded values are 6, 8, or 10
 *
 * @param {object} el - The element
 * @param {string} baseline - The baseline pixel value
 * @return {void}
 */

export function heightSet(el, baseline) {
	el.style.height = "auto";
	el.style.paddingTop = "";
	var height = getHeight(el, "full");
	var newHeight = heightRound(height, baseline);
	el.style.height = newHeight;
	el.style.paddingTop = "0";
	console.log("Baseline set");
}

export function getHeight(el, type) {
	if (type === "inner")
		// .innerheight()
		return el.clientHeight;
	else if (type === "outer")
		// .outerheight()
		return el.offsetHeight;
	let s = window.getComputedStyle(el, null);
	if (type === "height")
		// .height()
		return (
			el.clientHeight -
			parseInt(s.getPropertyValue("padding-top")) -
			parseInt(s.getPropertyValue("padding-bottom"))
		);
	else if (type === "full")
		// .outerheight(includeMargins = true)
		return (
			el.offsetHeight +
			parseInt(s.getPropertyValue("margin-top")) +
			parseInt(s.getPropertyValue("margin-bottom"))
		);
	return null;
}
